import React, { FC, useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Comment, { CommentInfo } from "../../common/comments/Comment/Comment";
import { adaptComments } from "../../../operation/adaptComments";
import { communityController } from "../../../services/community.controller";
import { communitiesInitial } from "../../../actions/communities";
import { CommunityRecordView } from "../../../models/community.models";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import NoGoals from "../../Achievements/AchievLists/NoGoals/NoGoals";
import { CommunityContext } from "../CommunityContextProvider/CommunityContextProvider";
import { reactionController } from "../../../services/reaction.controller";
import { dataInitial } from "../../../actions/data";
import { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import { roles } from "../../../constants/role";
import { LoadOnScrollParams } from '../../../types/LoadOnScroll.types.js';

export const Messages: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));
  const communityId = localStorage.getItem("groupId") || "";

  const messages: CommunityRecordView[] = useAppSelector(
    (state) => state.communitiesReducer.messages
  );

  const [loader, setLoader] = useState<boolean>(false);
  const {
    messagesTotalItems,
    setMessagesTotalItems,
    handleSnackbar,
    messagesPage,
    setMessagesPage,
  } = useContext(CommunityContext);
  const [additionalLoader, setAdditionalLoader] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    //if we change community, we have to reset community data
    dispatch(communitiesInitial.messages([]));
    setMessagesPage(0);
    setIsFetching(true);
    return () => {
      dispatch(communitiesInitial.messages([]));
    };
    /* eslint-disable-next-line */
  }, [communityId]);

  useEffect(() => {
    if (isFetching) {
      communityController
        .messages(communityId, messagesPage)
        .then((res) => {
          dispatch(
            communitiesInitial.messages([...messages, ...res.data.items])
          );
          setMessagesTotalItems(res.data.totalItems);
          setMessagesPage((prevState) => prevState + 1);
          setLoader(false);
        })
        .catch(() => handleSnackbar("error"))
        .finally(() => setIsFetching(false));
    }
    /* eslint-disable-next-line */
  }, [isFetching]);

  useEffect(() => {
    reactionController
      .smiles()
      .then((res) => dispatch(dataInitial.smiles(res.data.items)))
      .catch(() => handleSnackbar("error"));
    /* eslint-disable-next-line */
  }, []);

  const handleLoadOnScroll = (params: LoadOnScrollParams) => {
    const { scrollHeight, scrollTop, viewportHeight } = params;
    if (
      (scrollHeight - (scrollTop + viewportHeight) < 100)
      && (messages.length < messagesTotalItems)
    ) {
      setIsFetching(true);
      setAdditionalLoader(true);
    }
  };

  const scrollHandler = (e: any) => {
    handleLoadOnScroll({
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      viewportHeight: window.innerHeight,
    });
  };

  const windowScrollHandler = () => {
    handleLoadOnScroll({
      scrollHeight: document.documentElement.scrollHeight,
      scrollTop: window.scrollY,
      viewportHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    document.getElementById("main-scroll")?.addEventListener("scroll", scrollHandler);
    window.addEventListener("scroll", windowScrollHandler);
    return function () {
      document.getElementById("main-scroll")?.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("scroll", windowScrollHandler);
      setAdditionalLoader(false);
    };
    /* eslint-disable-next-line */
  }, [messages.length, messagesTotalItems]);

  const handleReqactionError = () => {
    handleSnackbar("error");
  };

  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const updateMessages = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        communityController
          .messages(communityId, 0, (messagesPage + 1) * 10)
          .then((res) => {
            dispatch(communitiesInitial.messages(res.data.items));
            setMessagesTotalItems(res.data.totalItems);
          });
        resolve();
      }, 500);
    });
  };

  const deleteMessage = (id: string) => {
    communityController
      .deleteMessage(communityId, id)
      .then(() => {
        updateMessages();
      })
      .catch(() => handleSnackbar("error"));
  };

  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const editMessage =
    (comment: CommentInfo) =>
    (newContent: string, closeModal: () => void, files?: any[]) => {
      communityController
        .editMessage(communityId, comment.id, { content: newContent })
        .then(() => {
          const deleteAttach = () => {
            if (deleteFiles.length > 0) {
              return communityController.deleteAttachments(
                communityId,
                comment.id,
                {
                  attachmentsIds: deleteFiles,
                }
              );
            }
          };
          const addAttach = () => {
            const newFiles = files?.filter(
              (item) => item.webkitRelativePath === "" //take only new files
            );
            if (newFiles && newFiles.length > 0) {
              const formData = new FormData();
              [...newFiles].forEach((file) => {
                formData.append("file", file);
              });

              return communityController.addAttachments(
                communityId,
                comment.id,
                formData
              );
            }
          };
          Promise.all([deleteAttach(), addAttach()])
            .then(() => {
              updateMessages();
              closeModal();
            })
            .catch(() => handleSnackbar("error"));
        })
        .catch(() => handleSnackbar("error"));
    };

  const [editCommentId, setEditCommentId] = useState<string>("");

  const commentButtons = (
    id: string,
    authorId: string
  ): buttonInfo[] | undefined => {
    if (currentUser.id === authorId) {
      return [
        {
          name: "Редактировать",
          action: () => setEditCommentId(id),
        },
        {
          name: "Удалить",
          action: () => deleteMessage(id),
        },
      ];
    }
    if (currentUser.role === roles.admin) {
      return [{
        name: "Удалить",
        action: () => deleteMessage(id),
      }];
    }
  };

  return (
    <>
      {loader ? (
        <LoadProgress />
      ) : messages.length > 0 ? (
        <>
          <Box sx={{ px: isMobile ? 2 : 0 }}>
            {adaptComments(messages).map((item, index) => (
              <Comment
                key={index}
                commentData={item}
                handleError={handleReqactionError}
                editButtons={commentButtons(item.id, item.author.id)}
                idForEdit={editCommentId}
                setIdForEdit={setEditCommentId}
                handleEdit={editMessage(item)}
              />
            ))}
          </Box>
          {additionalLoader && <LoadProgress />}
        </>
      ) : (
        <NoGoals text="Здесь еще нет сообщений" />
      )}
    </>
  );
};

export default Messages;
