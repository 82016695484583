import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { reviewThunks } from '../../../reducer/review'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import RatingCustom from './RatingCustom'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { relativeReviewListSelect } from '../../../reducer/selectors/reviewSelectors'
import UserAvatar from '../../common/User/UserAvatar/UserAvatar'
import { ReviewContext } from '../RewievContextProvider/ReviewContextProvider'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { decodeBase64 } from '../utils/decodeId'

interface IProps {
  handleModal: () => void
  openModal: boolean
}

const styleStack = { display: 'flex', flexDirection: 'column', alignItems: 'center' }

const styleTypography = {
  display: 'flex',
  gap: '12px',
}

const RatingModal = ({ openModal, handleModal }: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
  const { handleApprovedModal, reviewIdFromNotification, openRatingModal } = useContext(ReviewContext)
  const relativeReviewList = useAppSelector(relativeReviewListSelect)

  const [estimation, setEstimation] = useState<number>(0)

  const navigate = useNavigate()
  const { personId } = useParams()
  const dispatch = useAppDispatch()

  const currentUserId = useMemo(() => (
    JSON.parse(
      localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}',
    ).id
  ), []);

  const handleFetchRelativeReviews = () => {
    dispatch(reviewThunks.getFetchedRelativeReviewList(currentUserId))
      .unwrap()
      .catch((e) => console.log(e))
  };

  useEffect(() => {
    handleFetchRelativeReviews();
  }, [currentUserId]);

  const currentReview = useMemo(() => (
    relativeReviewList.find((el) => el.id === reviewIdFromNotification)
  ), [relativeReviewList, reviewIdFromNotification])

  const isVotedReview = useMemo(() => {
    const currentReviewer = currentReview?.reviewers.find((el) => (
      (el.reviewer.id === currentUserId)
      || (decodeBase64(el.reviewer.id) === currentUserId)
    ));

    return !!currentReviewer?.voted;
  }, [currentReview, currentUserId]);

  // ReviewContext.handleRatingModal() function works as toggler, so we need to carefully
  // check conditions for open rating modal.
  // Open rating modal only in case:
  //   1) review is selected
  //   2) it is not voted by current user and
  //   3) modal is not open currently
  const modalShouldOpen = useMemo(() => (
    reviewIdFromNotification && currentReview && !isVotedReview
  ), [reviewIdFromNotification, currentReview, isVotedReview])

  useEffect(() => {
    if (modalShouldOpen && !openRatingModal) {
      handleModal();
    } else if (!modalShouldOpen && openRatingModal) {
      handleModal();
    }
  }, [modalShouldOpen, openRatingModal])

  // Remove reviewId from URL
  useEffect(() => {
    if (reviewIdFromNotification && !modalShouldOpen) {
      navigate(`/profile/${personId}`, { replace: true })
    }
  }, [reviewIdFromNotification, modalShouldOpen]);

  const handleSend = () => {
    if (estimation) {
      dispatch(reviewThunks.setEstimationForReview({ reviewId: reviewIdFromNotification, estimation }))
    }
    handleApprovedModal()
    handleRatingModal()
    handleFetchRelativeReviews()
  }

  const handleRatingModal = () => {
    handleModal()
    setEstimation(0)
    navigate(`/profile/${personId}`, { replace: true })
  }

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleRatingModal}
      width='532px'
      height={!isMobile ? '438px' : undefined}
      contentDividers
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleSend}
          handleCancel={handleRatingModal}
          nameButtonSend={'Сохранить'}
          disableSend={estimation === 0}
        />
      }
    >
      <Stack gap={'24px'} sx={styleStack}>
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          Оцените владение навыком
        </Typography>
        <Typography variant='h4'>{currentReview?.skill.name}</Typography>
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          У сотрудника
        </Typography>
        <Typography variant='h4' sx={styleTypography} alignItems={'center'} justifyContent={'center'}>
          {currentReview ? <UserAvatar userInfo={currentReview.owner} /> : ''}
          {`${currentReview?.owner.name} ${currentReview?.owner.surname}`}
        </Typography>
        <RatingCustom setEstimation={setEstimation} estimation={estimation} />
      </Stack>
    </DialogWrapper>
  )
}

export default RatingModal
