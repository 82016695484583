import React, { FC, useState, useRef, useContext } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Popover from "@mui/material/Popover";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

import CalendarPopover from "../CalendarPopover/CalendarPopover";
import CalendarPopup from "../CalendarPopup/CalendarPopup";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CalendarContext } from "../CalendarContext/CalendarContextProvider";

import type { CalendarEvent } from "../../../types/CalendarEvent.type";
import type { CalendarEventType } from '../../../reducer/calendar';

interface CalendarDayProps {
	pickersDayProps: PickersDayProps<Date>;
	date: string;
}

export const intToColor = (value: number) => (
	`#${(value & 0xffffff).toString(16).padStart(6, '0')}`
);

export const getBGColor = (types: CalendarEventType[], eventTypeName: string) => {
	const eventType = types.find((item) => (
		item.typeEvent === eventTypeName
	));
	return intToColor(eventType?.color ?? 0);
};

export const generateStyleBackgrounds = (types: CalendarEventType[]) => (
	Object.fromEntries(
		types.map((type, index) => ([
			`&.calendar-event-${index}`,
			{
				backgroundColor: intToColor(type.color),
				color: "rgba(230, 237, 245, 1)",
			},
		]))
	)
);

export const CalendarDay: FC<CalendarDayProps> = ({ pickersDayProps, date }) => {
	const dayEvents = useAppSelector(
		(state) => state.calendarReducer.allEvents[date]
	);
	const eventTypes: CalendarEventType[] = useAppSelector(
		(state) => state.calendarReducer.eventTypes
	);
	const eventsFilter = useAppSelector(
		(state) => state.calendarReducer.eventsFilter
	);
	const filteredDayEvents = dayEvents?.filter(
		(event: CalendarEvent) => eventsFilter.includes(event.calendarEventType)
	);

	const { weekends } = useContext(CalendarContext);

	const [openedPopover, setOpenedPopover] = useState<number>(-1);
	const popoverAnchors = useRef<Map<number, HTMLElement>>(new Map());

	const refAnchor = (eventKey: number) => (node: HTMLElement) => {
		const anchorsMap = popoverAnchors.current;
		if (node) {
			anchorsMap.set(eventKey, node);
		} else {
			anchorsMap.delete(eventKey);
		}
	};

	const [openPopup, setOpenPopup] = useState<boolean>(false);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

	const handlePopup = (e?: any) => {
		e?.stopPropagation();
		setOpenPopup((prev) => !prev);
	};

	const closePopup = () => {
		setOpenPopup(false);
	};

	const popoverEnter = (targetPopover: number) => {
		setOpenedPopover(targetPopover);
	};

	const popoverLeave = () => {
		setOpenedPopover(-1);
	};

	const handleWrongClickEvent = (e?: any) => {
		e?.stopPropagation();
	};

	const boxStyle = {
		display: "inherit",
		padding: "0px 8px",
		alignItems: "center",
		lineHeight: "20px",
		width: isMobile ? "11vw" : "5vw",
		height: "20px",
		borderRadius: "4px",
		margin: "2px 0px",
	};

	const { key, ...pickersDayRest } = pickersDayProps;

	return (
		<Container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				backgroundColor: weekends.has(
					key?.toString().substring(4, 15)
				) ? "rgba(185, 215, 255, 0.08)" : undefined,
			}}
		>
			<PickersDay {...pickersDayRest} />
			{filteredDayEvents && (
				<Box>
					{filteredDayEvents.map((ce: CalendarEvent, index: number) => {
						ce = structuredClone(ce);
						return index < 2 && (
							<div key={ce.id!}>
								<Box
									ref={refAnchor(ce.id!)}
									onMouseEnter={() => popoverEnter(ce.id!)}
									onMouseLeave={popoverLeave}
									aria-owns="mouse-over-popover"
									aria-haspopup="true"
									p={1}
									onClick={handlePopup}
									{...boxStyle}
									bgcolor={getBGColor(eventTypes, ce.calendarEventType)}
									sx={{
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
										"&:hover": {
											backgroundColor: "rgba(185, 215, 255, 0.08)",
											borderRadius: "8px",
											cursor: "pointer",
										},
									}}
								>
									{ce.name}
								</Box>
								{!isMobile && <Popover
									id="mouse-over-popover"
									sx={{
										pointerEvents: "none",
									}}
									open={openedPopover === ce.id!}
									anchorEl={popoverAnchors.current.get(ce.id!)}
									anchorOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									onClose={popoverLeave}
									disableRestoreFocus
									PaperProps={{
										sx: { borderRadius: "12px", pointerEvents: "auto" },
										onMouseEnter: () => popoverEnter(ce.id!),
										onMouseLeave: popoverLeave,
									}}
									onClick={handleWrongClickEvent}
								>
									<CalendarPopover calendarEvent={ce} closePopover={popoverLeave} />
								</Popover>}
							</div>
						);
					})}
					{!isMobile
						&& filteredDayEvents.length > 2
						&& (
							<Box
								onClick={handlePopup}
								{...boxStyle}
								lineHeight="12px"
								height="12px"
								fontSize="13px"
								bgcolor="rgba(185, 215, 255, 0.08)"
								sx={{
									overflow: "hidden",
									whiteSpace: "nowrap",
									textOverflow: "ellipsis",
									"&:hover": {
										borderRadius: "8px",
										cursor: "pointer",
									},
								}}
							>
								{`Ещё ${filteredDayEvents.length - 2}`}
							</Box>
						)
					}
					<Dialog
						open={openPopup}
						onClose={handlePopup}
						aria-labelledby="dialog"
						aria-describedby="dialog-open"
						fullScreen={isMobile}
					>
						<CalendarPopup calendarDayEvents={filteredDayEvents} onClose={closePopup} />
					</Dialog>
				</Box>
			)}
		</Container>
	);
};

export default CalendarDay;