import React, { FC, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ReactComponent as NotificationsIcon } from "../../../assets/icons/notifications.svg";
import { determineDate } from "../../../operation/determineDate";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { dataInitial } from "../../../actions/data";
import { notificationsController } from "../../../services/notifications.controller";

import type { NotificationView } from "../../../models/notifications.model";
import Button from "@mui/material/Button";

import { decodeBase64 } from '../../Review/utils/decodeId';
import { getReviewId } from '../../Review/utils/getReviewId';
import { relativeReviewListSelect } from '../../../reducer/selectors/reviewSelectors';
import { reviewThunks } from '../../../reducer/review';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

interface NotificationsContentProps {
  notificationsLoader: boolean;
  seen: boolean;
}

const reviewableNotifications = ['CREATE_REVIEW', 'EDIT_REVIEW', 'REMINDER_REVIEW'];

export const NotificationsContent: FC<NotificationsContentProps> = ({
  notificationsLoader,
  seen,
}) => {
  const notificationBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderRadius: "12px",
    padding: "24px",
    backgroundColor: "rgba(185, 215, 255, 0.08)",
    wordBreak: "break-word",
  };

  const notificationLinkStyle = {
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.10) inset",
    backdropFilter: "blur(12.5px)",
    ":hover": {
      backgroundColor: "rgba(203, 225, 255, 0.17)",
      cursor: "pointer",
    },
    ":active": {
      backgroundColor: "rgba(51, 146, 255, 0.25)",
    },
    color: "rgba(230, 237, 245, 1)",
    textDecoration: "none",
  };

  const notificationMarkStyle = {
    width: "8px",
    height: "8px",
    backgroundColor: "rgba(24, 132, 255, 1)",
    borderRadius: "4px",
  };

  const buttonStyle = {
    borderRadius: "60px",
    backgroundColor: "rgba(24, 132, 255, 1)",
    p: "4px 16px",
    height: "40px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    whiteSpace: "nowrap",
    minWidth: "auto",
    color:'rgba(230, 237, 245, 1)',
    "&:hover": {
      backgroundColor: "rgba(26, 117, 222, 1)",
    },
  };

  const dispatch = useDispatch();
  const liveNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.liveNotifications
  );
  const seenNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.seenNotifications
  );
  const notifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.notifications
  );

  const currentUserId = useMemo(() => (
    JSON.parse(
      localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}',
    ).id
  ), []);

  const appDispatch = useAppDispatch();

  useEffect(() => {
    appDispatch(reviewThunks.getFetchedRelativeReviewList(currentUserId))
      .unwrap()
      .catch((e) => console.log(e))
  }, [currentUserId]);

  const relativeReviewList = useAppSelector(relativeReviewListSelect);

  const isVotedReview = (link: string) => {
    const reviewId = getReviewId(link)
    const currentReview = relativeReviewList.find((el) => el.id === reviewId)
    const currentReviewer = currentReview?.reviewers.find((el) => (
      (el.reviewer.id === currentUserId)
      || (decodeBase64(el.reviewer.id) === currentUserId)
    ))

    return !!currentReviewer?.voted
  };

  const showReviewButton = (notification: NotificationView) => (
    reviewableNotifications.includes(notification.notificationType)
    && !isVotedReview(notification.link)
  );

  return (
    <Box display="flex" flexDirection="column" gap="4px">
      {notifications.length > 0 ? (
        <>
          {notifications.map((notification) => {
            const notificationBoxProps = {
              sx: {
                ...notificationBoxStyle,
                ...notificationLinkStyle,
              },
              component: NavLink,
              to: `${notification.link}`,
              ...!seen && { onClick: () => {
                notificationsController.markNotification(notification.id);
                dispatch(dataInitial.liveNotifications(
                  liveNotifications.filter(liveNotification => liveNotification.id !== notification.id)
                ));
                dispatch(dataInitial.seenNotifications(
                  [{ ...notification, seen: true }, ...seenNotifications]
                ));
                dispatch(dataInitial.notifications(
                  notifications.filter(notificationItem => notificationItem.id !== notification.id)
                ));
              }},
            };
            return (
              <Box {...notificationBoxProps} key={notification.id}>
                <Typography variant="body2" pr="24px">{notification.content}</Typography>
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">{determineDate(notification.date)}</Typography>
                  <Box display={'flex'} gap={'24px'} alignItems="center" mt={'24px'}>
                    {showReviewButton(notification) && <Button sx={buttonStyle} >Провести ревью</Button>}
                    {!notification.seen && <Box sx={notificationMarkStyle} />}
                  </Box>
                </Box>
              </Box>
            );
          })}
          {notificationsLoader && <LoadProgress />}
        </>
      ) : (
        notificationsLoader ? <LoadProgress /> : (
          <Box display="flex" flexDirection="column" alignItems="center" gap="16px">
            <NotificationsIcon />
            <Typography variant="body1" color="rgba(229, 235, 242, 0.65)">
              {seen ? "Нет уведомлений в архиве" : "Нет непрочитанных уведомлений"}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default NotificationsContent;