import React, { FC, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import Stack from '@mui/material/Stack';
import UserAvatar from '../../../common/User/UserAvatar/UserAvatar';
import { PersonView } from '../../../../models/profile.models';
import CustomButton from '../../../common/Buttons/CustomButton/CustomButton';
import { EditAvatarModal } from './EditAvatarModal';

interface EditAvatarProp {
  newAvatar: File | null;
  setNewAvatar: any;
}

export const EditAvatar: FC<EditAvatarProp> = ({ newAvatar, setNewAvatar }) => {
  const [src, setSrc] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleImgChange = (e: any) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  const userData: PersonView = useAppSelector(
    (state) => state.profileReducer.editProfile,
  );

  const handleAvatar = (blob: Blob) => {
    setNewAvatar(blob);
  };

  return (
    <Stack spacing='12px' direction='row'>
      <UserAvatar withBorder={false} userInfo={userData} newAvatar={newAvatar} />
      <CustomButton
        color='secondary'
        attachButton
        onChange={handleImgChange}
        acceptFile='image/*'
        text='Заменить'
      />
      <EditAvatarModal
        modalOpen={modalOpen}
        src={src}
        setModalOpen={setModalOpen}
        setNewAvatar={handleAvatar}
      />
    </Stack>
  );
};

export default EditAvatar;
