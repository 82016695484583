import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import Snackbars from '../../common/Snackbars/Snackbars'
import { AppContext } from '../../shared/AppContextProvider'
import { roles } from '../../../constants/role'
import { skillController } from '../../../services/skill.controller'
import { Reviewers, ReviewList, Skill } from '../../../models/review.models'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { relativeReviewListSelect, reviewListSelect, reviewSnackBar } from '../../../reducer/selectors/reviewSelectors'
import { decodeBase64 } from '../utils/decodeId'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { reviewActions } from '../../../reducer/review'

interface ReviewContextProps {
  children: React.ReactNode
}

interface ReviewContextValue {
  handleReviewModal: () => void
  handleRatingModal: () => void
  openReviewModal: boolean
  openRatingModal: boolean
  editableAccess: boolean
  skillList: Skill[]
  userId: string
  currentUserId: string
  setReviewId: Dispatch<SetStateAction<string>>
  reviewId: string
  reviewIdFromNotification: string
  setReviewIdFromNotification: Dispatch<SetStateAction<string>>
  openApprovedModal: boolean
  handleApprovedModal: () => void
  openEditModal: boolean
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  handleEditModal: () => void
  hasReviewer: boolean
  listPage: number
  setListPage: Dispatch<SetStateAction<number>>
}

let defaultValue: ReviewContextValue = {
  handleReviewModal: () => { },
  handleRatingModal: () => { },
  openReviewModal: false,
  openRatingModal: false,
  editableAccess: false,
  skillList: [],
  userId: '',
  currentUserId: '',
  setReviewId: () => { },
  reviewId: '',
  reviewIdFromNotification: '',
  setReviewIdFromNotification: () => { },
  openApprovedModal: false,
  handleApprovedModal: () => { },
  openEditModal: false,
  open: false,
  handleEditModal: () => { },
  setOpen: () => { },
  hasReviewer: false,
  listPage: 0,
  setListPage: () => {},
}
export const ReviewContext = createContext(defaultValue)

export const ReviewContextProvider = ({ children }: ReviewContextProps) => {
  const reviewList = useAppSelector(reviewListSelect)
  const relativeReviewList = useAppSelector(relativeReviewListSelect)
  const snackBar = useAppSelector(reviewSnackBar)

  const dispatch = useAppDispatch()

  const [skillList, setSkillList] = useState([])
  const [reviewId, setReviewId] = useState('')
  const [reviewIdFromNotification, setReviewIdFromNotification] = useState('')
  const [hasReviewer, setReviewer] = useState(false)
  const [listPage, setListPage] = useState<number>(0);

  const [openReviewModal, setOpenReviewModal] = useState(false)
  const [openRatingModal, setOpenRatingModal] = useState(false)
  const [openApprovedModal, setOpenApprovedModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const { user } = useContext(AppContext)
  const userId = localStorage.getItem('userId') || ''
  const currentUserId = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') || '{}').id
  const isCurrentUser = userId === currentUserId
  const userRole = user.role === roles.user
  const editableAccess = !userRole && !isCurrentUser

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const res = skillController.getSkills()
    res.then((res) => setSkillList(res.data.items))
  }, [])

  useEffect(() => {
    if (relativeReviewList) {
      const isReviewer = relativeReviewList.some((review: ReviewList) => {
        if (review.id !== reviewIdFromNotification) {
          return false;
        }

        return review.reviewers.some((reviewer: Reviewers) => {
          const reviewerId = review.reviewers.length === 1 ? decodeBase64(reviewer.reviewer.id) : reviewer.reviewer.id
          return reviewerId === currentUserId && !reviewer.voted
        })
      })
      setReviewer(isReviewer)
    }
  }, [relativeReviewList, reviewIdFromNotification])

  const handleReviewModal = () => {
    setOpenReviewModal(!openReviewModal)
  }

  const handleRatingModal = () => {
    setOpenRatingModal(!openRatingModal)
  }

  const handleApprovedModal = () => {
    setOpenApprovedModal(!openApprovedModal)
  }

  const handleEditModal = () => {
    setOpenEditModal(!openEditModal)
  }

  const handleClose = (value: boolean) => {
    dispatch(reviewActions.changeOpenSnackBar(value))
  }

  return (
    <ReviewContext.Provider
      value={{
        handleRatingModal,
        handleReviewModal,
        openReviewModal,
        openRatingModal,
        editableAccess,
        skillList,
        userId,
        open,
        setOpen,
        currentUserId,
        setReviewId,
        reviewId,
        reviewIdFromNotification,
        setReviewIdFromNotification,
        handleApprovedModal,
        openApprovedModal,
        handleEditModal,
        openEditModal,
        hasReviewer,
        listPage,
        setListPage,
      }}
    >
      {children}
      <Snackbars
        open={snackBar.open}
        setOpen={handleClose}
        type={snackBar.type}
        position={'center'}
        message={snackBar.message}
      />
    </ReviewContext.Provider>
  )
}
