import React, { FC, useContext } from "react";
import { useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";
import moment from "moment";

import { CommentView } from "../../../../../../models/comment.models";
import UserAvatar from "../../../../../common/User/UserAvatar/UserAvatar";
import PreviewFiles from "../../../../../common/Attachments/PreviewFiles/PreviewFiles";
import EditPopover from "../../../../../common/Modals/EditPopover/EditPopover";
import Delete from "../../../../../../assets/icons/Delete";
import { achievementController } from "../../../../../../services/achievement.controller";
import { AchievContext } from "../../../../AchievContextProvider/AchievContextProvider";
import { achievementsInitial } from "../../../../../../actions/achievements";
import { AppContext } from "../../../../../shared/AppContextProvider";

interface CommentItemProps {
  comment: CommentView;
  lastItem?: boolean;
}

const styleContent = {
  wordBreak: "break-word",
  whiteSpace: "pre-wrap",
};

export const CommentItem: FC<CommentItemProps> = ({ comment, lastItem }) => {
  const dispatch = useDispatch();
  const achievementId = sessionStorage.getItem("achievementId") || "";
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const { handleSnackbar } = useContext(AchievContext);
  const { isMobile } = useContext(AppContext);

  const divider = (
    <Box
      sx={{
        borderLeft: "2px solid rgba(241, 247, 255, 0.12)",
        height: "100%",
        m: "4px 50%",
      }}
    />
  );

  const handleDelete = () => {
    achievementController
      .deleteComment(achievementId, comment.parentRecordId, comment.id)
      .then(() =>
        achievementController
          .getComments(achievementId, comment.parentRecordId)
          .then((result) => {
            dispatch(
              achievementsInitial.achievementComments(result.data.items)
            );
          })
      )
      .catch(() => handleSnackbar("error"));
  };

  const editButton = [
    {
      name: "Удалить комментарий",
      action: handleDelete,
      startIcon: <Delete color="secondary" />,
    },
  ];
  const updType = comment.recordType === "TASK_STATUS_UPDATE";
  const editAccess = comment.author.id === currentUser.id && !updType;

  return (
    <Box display="flex" gap={2}>
      <Box display="flex" flexDirection="column">
        <UserAvatar userInfo={comment.author} />
        {!lastItem && divider}
      </Box>
      <Box sx={{ pb: "24px", width: "100%" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Box
            display="flex"
            gap={"4px"}
            alignItems={isMobile ? "flex-start" : "center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Typography variant="body2">
              {comment.author.name} {comment.author.surname}
            </Typography>
            <Typography color={(theme) => theme.palette.text.secondary}>
              написал(а) {moment(comment.date).format("DD.MM.YYYY HH:mm")}
            </Typography>
          </Box>
          {editAccess && <EditPopover buttonsData={editButton} />}
        </Box>
        <Typography
          variant={updType ? "body1" : "body2"}
          color={(theme) =>
            updType ? theme.palette.text.secondary : theme.palette.text.primary
          }
          mb={comment.attachments?.length! > 0 ? 1 : undefined}
          sx={styleContent}
        >
          {comment.content}
        </Typography>
        <PreviewFiles
          files={comment.attachments}
          size="compact"
          type="download"
        />
      </Box>
    </Box>
  );
};

export default CommentItem;
