import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";

import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import { AppContext } from "../../../shared/AppContextProvider";
import { KbArticleListView, KbSectionView } from "../../../../models/kb.models";
import ShowMoreButton from "../../ShowMoreButton/ShowMoreButton";
import EditPopover from "../../../common/Modals/EditPopover/EditPopover";
import { roles } from "../../../../constants/role";
import { kbController } from "../../../../services/kb.controller";
import { KbContext } from "../../KbContextProvider/KbContextProvider";
import ConfirmModal from "../../../common/Modals/ConfirmModal/ConfirmModal";
import SectionModal from "../../SectionModal/SectionModal";
import { dataInitial } from "../../../../actions/data";

interface SectionWrapperProps {
  section: KbSectionView;
  updSections: () => void;
}

export const SectionWrapper: FC<SectionWrapperProps> = ({
  section,
  updSections,
}) => {
  const { isMobile, user } = useContext(AppContext);
  const { handleSnackbar } = useContext(KbContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sections: KbSectionView[] = useAppSelector(
    (state) => state.dataReducer.articleSections
  );

  const isNotUserRole = user.role !== roles.user;

  const style = {
    p: 3,
    borderRadius: "12px",
    position: "relative",
  };

  const isShowMore = section.articleCount > section.topArticles?.length;
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    setPage(0);
  }, [sections]);

  const getNextArticles = () => {
    kbController
      .articles(section.id, page + 1)
      .then((res) => {
        const test = sections.map((item) =>
          item.id === section.id
            ? { ...item, topArticles: [...item.topArticles, ...res.data.items] }
            : item
        );
        dispatch(dataInitial.articleSections(test));
      })
      .then(() => setPage((prev) => prev + 1))
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const emptyArticleName = "no name";

  const routeToArticle = (
    event: React.MouseEvent,
    article: KbArticleListView,
    sectionId: string
  ) => {
    event.preventDefault();
    localStorage.setItem(
      "article",
      JSON.stringify({ articleId: article.id, sectionId: sectionId })
    );
    navigate(`/knowledge/${article.name ? article.name : emptyArticleName}`);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleConfirmModal = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const handleDelete = () => {
    kbController
      .deleteSection(section.id)
      .then(() => {
        handleConfirmModal();
        updSections();
      })
      .catch(() => {
        handleSnackbar("error");
      });
  };

  const [sectionModal, setSectionModal] = useState<boolean>(false);
  const handleSectioneModal = () => {
    setSectionModal((prev) => !prev);
  };

  const styleLink = {
    width: "fit-content",
    textDecoration: "none",
    overflowWrap: "anywhere",
    "&:hover": {
      cursor: "pointer",
    },
  };

  const popoverButtons = [
    { name: "Редактировать", action: handleSectioneModal },
    { name: "Удалить", action: handleConfirmModal },
  ];

  return (
    <>
      <Paper sx={style}>
        <Grid
          container
          columns={{ mobile: isMobile ? 2 : 3 }}
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid container columns={2} mobile={2} spacing={{ mobile: 2 }}>
            <Grid item mobile={1}>
              <Typography variant="h4" sx={{ wordWrap: "break-word" }}>
                {section?.name}
              </Typography>
            </Grid>
            <Grid item mobile={1}>
              <Stack gap="12px">
                {section?.topArticles?.map((item, index) => (
                  <Link
                    variant="body2"
                    key={index}
                    sx={styleLink}
                    onClick={(event) => routeToArticle(event, item, section.id)}
                  >
                    {item.name ? item.name : emptyArticleName}
                  </Link>
                ))}
                {/* for search articles */}
                {section?.articles?.map((item, index) => (
                  <Link
                    variant="body2"
                    key={index}
                    sx={styleLink}
                    onClick={(event) => routeToArticle(event, item, section.id)}
                  >
                    {item.name}
                  </Link>
                ))}
                {isShowMore && <ShowMoreButton onClick={getNextArticles} />}
              </Stack>
            </Grid>
          </Grid>
          {isNotUserRole && (
            <Grid mobile={1} container justifyContent="flex-end">
              <EditPopover buttonsData={popoverButtons} />
            </Grid>
          )}
        </Grid>
      </Paper>
      {openConfirmModal && (
        <ConfirmModal
          openConfirmModal={openConfirmModal}
          handleModal={handleConfirmModal}
          nameConfirmButton="Удалить"
          handleConfirm={handleDelete}
          text="Раздел и его статьи будут удалены."
        />
      )}
      {sectionModal && (
        <SectionModal
          open={sectionModal}
          handleModal={handleSectioneModal}
          type="edit"
          sectionInfo={section}
          updSections={updSections}
        />
      )}
    </>
  );
};

export default SectionWrapper;
