import React, { FC, useState, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import { dataInitial } from "../../actions/data";
import { AppContext } from "../shared/AppContextProvider";
import Snackbars from "../common/Snackbars/Snackbars";
import CustomButton from "../common/Buttons/CustomButton/CustomButton";
import ConfirmModal from "../common/Modals/ConfirmModal/ConfirmModal";
import NotificationsPopover from "./NotificationsPopover/NotificationsPopover";
import NotificationsToggleButtons from "./NotificationsToggleButtons/NotificationsToggleButtons";
import NotificationsContent from "./NotificationsContent/NotificationsContent";
import { notificationsController } from "../../services/notifications.controller";

import type { NotificationView } from "../../models/notifications.model";
import { LoadOnScrollParams } from '../../types/LoadOnScroll.types';

export const Notifications: FC = () => {
	const [seen, setSeen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onSeenToggle = (newSeen: boolean) => {
    if (newSeen !== seen) {
      setNotificationsLoader(true);
      dispatch(dataInitial.notifications([]));
      setSeen(newSeen);
    }
  };
  const { isMobile } = useContext(AppContext);

  const notificationsBoxStyle = {
    display: "flex",
    flexDirection: "column",
    gap: isMobile ? "24px" : "22px",
    mx: "16px",
  };

  const notifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.notifications
  );
  const seenNotifications: NotificationView[] = useAppSelector(
    (state) => state.dataReducer.seenNotifications
  );
  const [notificationsLoader, setNotificationsLoader] = useState<boolean>(true);
  const totalNotifications = useRef<number>(0);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const onNotificationsError = () => setOpenSnackbar(true);

  const fetchNotifications = (seen: boolean, oldNotifications: NotificationView[] = [], page?: number) => {
    setNotificationsLoader(true);
    notificationsController
      .notifications(seen, page)
      .then((res) => {
        const newNotifications = [...oldNotifications, ...res.data.items];
        dispatch(dataInitial.notifications(newNotifications));
        setNotificationsLoader(false);
        totalNotifications.current = res.data.totalItems;
      })
      .catch(() => {
        onNotificationsError();
        setNotificationsLoader(false);
      });
  };

  useEffect(() => {
    fetchNotifications(seen);
    return () => {
      seen && dispatch(dataInitial.notifications([]));
    };
    /* eslint-disable */
  }, [seen]);

  const handleLoadOnScroll = (params: LoadOnScrollParams) => {
    const { scrollHeight, scrollTop, viewportHeight } = params;

    if (
      (scrollHeight - (scrollTop + viewportHeight) < 100)
      && (notifications.length < totalNotifications.current)
      && !notificationsLoader
    ) {
      fetchNotifications(seen, notifications, Math.ceil(notifications.length / 10));  // Page size is 10
    }
  };

  const scrollHandler = (e: any) => {
    handleLoadOnScroll({
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      viewportHeight: window.innerHeight,
    });
  };

  const windowScrollHandler = () => {
    handleLoadOnScroll({
      scrollHeight: document.documentElement.scrollHeight,
      scrollTop: window.scrollY,
      viewportHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    document.getElementById("main-scroll")?.addEventListener("scroll", scrollHandler);
    window.addEventListener("scroll", windowScrollHandler);
    return () => {
      document.getElementById("main-scroll")?.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("scroll", windowScrollHandler);
    };
  }, [notifications, notificationsLoader]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDelete = () => {
    dispatch(dataInitial.notifications([]));
    dispatch(dataInitial.seenNotifications([]));
    closeDeleteModal();
    notificationsController
      .deleteAllSeenNotifications()
      .then(() => {
        totalNotifications.current = 0;
      })
      .catch(() => {
        onNotificationsError();
      });
  };

  const markAllNotificationsAsRead = () => {
    setNotificationsLoader(true);
    notificationsController
      .markAllNotifications()
      .then((res) => {
        dispatch(dataInitial.notifications([]));
        dispatch(dataInitial.liveNotifications([]));
        dispatch(dataInitial.seenNotifications([...notifications, ...seenNotifications]));
        setNotificationsLoader(false);
        totalNotifications.current = res.data.totalItems;
      })
      .catch(() => {
        onNotificationsError();
        setNotificationsLoader(false);
      });
  };

  const notificationsActionDisabled = notifications.length === 0;
  const notificationsActionButton = isMobile ? (
    seen ? (
      <NotificationsPopover
        buttonText="Удалить все прочитанные"
        buttonAction={openDeleteModal}
        buttonDisabled={notificationsActionDisabled}
      />
    ) : (
      <NotificationsPopover
        buttonText="Отметить все как прочитанные"
        buttonAction={markAllNotificationsAsRead}
        buttonDisabled={notificationsActionDisabled}
      />
    )
  ) : (
    seen ? (
      <CustomButton
        color="secondary"
        text="Удалить все прочитанные"
        onClick={openDeleteModal}
        disabled={notificationsActionDisabled}
      />
    ) : (
      <CustomButton
        color="secondary"
        text="Отметить все как прочитанные"
        onClick={markAllNotificationsAsRead}
        disabled={notificationsActionDisabled}
      />
    )
  );

  return (
    <Box sx={notificationsBoxStyle}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant={isMobile ? "h4" : "h3"}>Уведомления</Typography>
        {notificationsActionButton}
        <ConfirmModal
          openConfirmModal={isDeleteModalOpen}
          handleModal={closeDeleteModal}
          nameConfirmButton="Удалить"
          handleConfirm={handleDelete}
          text={<>Все прочитанные уведомления будут удалены из архива.<br/></>}
        />
      </Box>
      <NotificationsToggleButtons onToggle={onSeenToggle} seen={seen} />
      <NotificationsContent
        notificationsLoader={notificationsLoader}
        seen={seen}
      />
      <Snackbars
				open={openSnackbar}
				setOpen={setOpenSnackbar}
				type="error"
				position="center"
				message="С Уведомлениями что-то не так, свяжитесь с администратором"
			/>
    </Box>
  );
};

export default Notifications;