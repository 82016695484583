import React, { useContext, useEffect, useId, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Badge, Box, Button, CardHeader, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import UserAvatar from '../../common/User/UserAvatar/UserAvatar'
import { styled, useTheme } from '@mui/material/styles'
import LinearProgressBar from '../../common/LinearProgressBar/LinearProgressBar'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { reviewEstimationsSelect } from '../../../reducer/selectors/reviewSelectors'
import { useGetReviewEstimations } from '../../../hooks/useGetReviewEstimations'
import { reviewThunks } from '../../../reducer/review'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { ReviewList } from '../../../models/review.models'
import Card from '@mui/material/Card'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import Delete from '../../../assets/icons/Delete'
import CardActions from './CardActions'
import { ReviewContext } from '../RewievContextProvider/ReviewContextProvider'
import Avatar from '@mui/material/Avatar'
import { PersonView } from '../../../models/profile.models'
import { editPersonSelect } from '../../../reducer/selectors/personSelectors'
import useMediaQuery from '@mui/material/useMediaQuery'
import { decodeBase64 } from '../utils/decodeId'

const styleBadgeWrapper = {
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  flexWrap: 'wrap',
  alignItems: 'center',
}

const styleActiveBadge = {
  '& .MuiBadge-badge': {
    backgroundColor: '#37BD5D',
    color: '#ffffff',
    margin: '0',
    padding: '0',
  },
}

const styleIcon = {
  width: '70%',
  height: '70%',
}

const styleBoxCardWrapper = {
  borderRadius: '12px',
  backgroundColor: 'rgba(185, 215, 255, 0.08)',
  p: 3,
  display: 'flex',
  flexDirection: 'column',
}

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',
}

const ColorTypography = styled(Typography)<ColorTypographyProps>(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color,
}))

const styleCardHeader = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingInline: '0px',
}

const styleBoxHeader = {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}

const buttonStyle = {
  borderRadius: "60px",
  backgroundColor: "rgba(24, 132, 255, 1)",
  p: "4px 16px",
  height: "40px",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  whiteSpace: "nowrap",
  minWidth: "auto",
  color: 'rgba(230, 237, 245, 1)',
  "&:hover": {
    backgroundColor: "rgba(26, 117, 222, 1)",
  },
}

interface ColorTypographyProps {
  color?: string
}

interface IProps {
  review: ReviewList
}

export const ReviewCard = ({ review }: IProps) => {
  const reviewers = review.reviewers
  const votedReviewers = reviewers.filter((el) => el.voted).length
  const reviewCompleted = reviewers.every((el) => el.voted)

  const { currentUserId } = useContext(ReviewContext)
  const currentUserReviewer = useMemo(() => (
    reviewers.find((el) => (
      (el.reviewer.id === currentUserId)
      || (decodeBase64(el.reviewer.id) === currentUserId)
    ))
  ), [reviewers, currentUserId]);

  const estimations = useAppSelector(reviewEstimationsSelect)
  const user: PersonView = useAppSelector(editPersonSelect)
  const { setReviewId, handleEditModal } = useContext(ReviewContext)

  const dispatch = useAppDispatch()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))

  const uniqueId = useId()
  const { estimationValue, estimationColor } = useGetReviewEstimations(estimations, review.id)

  useEffect(() => {
    if (reviewCompleted) {
      dispatch(reviewThunks.getFetchedAllEstimations({ reviewId: review.id }))
    }
  }, [review])

  const handleEdit = () => {
    setReviewId(review.id)
    handleEditModal()
  }

  const handleDelete = () => {
    dispatch(reviewThunks.deleteReview({ reviewId: review.id }))
  }

  const buttonsData = {
    edit: {
      name: 'Редактировать',
      action: handleEdit,
      startIcon: <EditOutlinedIcon />,
    },
    delete: {
      name: 'Удалить ревью',
      action: handleDelete,
      startIcon: <Delete color='secondary' />,
    },
  }

  let actionButtonsToShow

  if (user.blocked) {
    actionButtonsToShow = [buttonsData.delete]
  } else if ((reviewCompleted && reviewers.length === 0) || (reviewers.length !== 0 && !reviewCompleted)) {
    actionButtonsToShow = [buttonsData.edit, buttonsData.delete]
  } else {
    actionButtonsToShow = [buttonsData.delete]
  }

  return (
    <>
      <Card sx={{ ...styleBoxCardWrapper, margin: isMobile ? '20px' : '0px' }}>
        <CardHeader
          sx={styleCardHeader}
          title={
            <Box sx={styleBoxHeader}>
              <ColorTypography variant='subtitle1'>{review.skill.name}</ColorTypography>
              {reviewCompleted && (
                <ColorTypography variant='body2' color={estimationColor}>
                  {estimationValue} из 10
                </ColorTypography>
              )}
            </Box>
          }
          action={<CardActions actionButtons={actionButtonsToShow} />}
        ></CardHeader>
        <Box>
          {reviewCompleted ? (
            <LinearProgressBar progress={estimationValue ? estimationValue * 10 : 0} color={estimationColor} />
          ) : (
            <Box sx={styleBox}>
              <Box sx={styleBadgeWrapper}>
                {reviewers.map((el) => (
                  <Badge
                    key={el.reviewer?.id || uniqueId}
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    invisible={!el.voted}
                    badgeContent={<CheckIcon sx={styleIcon} />}
                    sx={styleActiveBadge}
                  >
                    {(reviewers.length !== 1 || el.reviewer.avatar) ? (
                      <UserAvatar userInfo={el.reviewer} />
                    ) : (
                      <Avatar sx={{ backgroundColor: 'rgba(203, 225, 255, 0.17)' }}> ? </Avatar>
                    )}
                  </Badge>
                ))}
              </Box>
              <Typography variant={'body1'} color={'secondary'}>
                Ревью в процессе {votedReviewers} из {reviewers.length}
              </Typography>
              {currentUserReviewer && !currentUserReviewer.voted && (
                <Box display={'flex'} gap={'24px'} alignItems="center" mt={'24px'}>
                  {<Button sx={buttonStyle} component={NavLink} to={`/profile/${user.id}/review/${review.id}`}>Провести ревью</Button>}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Card>
    </>
  )
}
