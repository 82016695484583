import jwtInterceoptor from './jwtInterceptor'
import { apiUrlPortal } from '../constants/apiUrl'
import { CreateReviewRequest, EditReviewRequest, ReviewList } from '../models/review.models'
import { reviewPageSize } from '../constants/review'

type BaseResponse<T> = {
  data: T
  status: number
  statusText: string
}

export const reviewController = {
  createReview: async (body: CreateReviewRequest): Promise<BaseResponse<ReviewList>> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/review/person/review`, body)
  },
  getReviewByID: async (reviewId: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/review/person/review/${reviewId}`)
  },
  editReview: async (reviewId: string, body: EditReviewRequest): Promise<any> => {
    return await jwtInterceoptor.put(`${apiUrlPortal}/review/person/review/${reviewId}`, body)
  },
  deleteReview: async (reviewId: string): Promise<any> => {
    return await jwtInterceoptor.delete(`${apiUrlPortal}/review/person/review/${reviewId}`)
  },
  setEstimation: async (reviewId: string, body: { rating: number }): Promise<any> => {
    return await jwtInterceoptor.post(`${apiUrlPortal}/review/person/review/${reviewId}/rating`, body)
  },
  getAllReview: async (
    personId: string,
    page?: number,
    size?: number,
  ): Promise<any> => {
    return await jwtInterceoptor.get(
      `${apiUrlPortal}/review/person/${personId}/owner/review?page=${(page ?? '')}&size=${(size ?? reviewPageSize)}`
    )
  },
  getAllRelativeReview: async (personId: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/review/person/${personId}/reviewer/review?size=0`)
  },
  getAllEstimations: async (reviewId: string): Promise<any> => {
    return await jwtInterceoptor.get(`${apiUrlPortal}/review/person/review/${reviewId}/rating`)
  },
}
