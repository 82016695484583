import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { reviewThunks } from '../../../reducer/review'
import DialogWrapper from '../../common/Modals/DialogWrapper/DialogWrapper'
import ModalButtons from '../../common/Buttons/ModalButtons/ModalButtons'
import RatingCustom from './RatingCustom'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { relativeReviewListSelect } from '../../../reducer/selectors/reviewSelectors'
import UserAvatar from '../../common/User/UserAvatar/UserAvatar'
import { ReviewContext } from '../RewievContextProvider/ReviewContextProvider'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

interface IProps {
  handleModal: () => void
  openModal: boolean
}

const styleStack = { display: 'flex', flexDirection: 'column', alignItems: 'center' }

const styleTypography = {
  display: 'flex',
  gap: '12px',
}

const RatingModal = ({ openModal, handleModal }: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('mobile'))
  const { handleApprovedModal, reviewIdFromNotification, hasReviewer } = useContext(ReviewContext)
  const relativeReviewList = useAppSelector(relativeReviewListSelect)

  const [estimation, setEstimation] = useState<number>(0)

  const navigate = useNavigate()
  const { personId } = useParams()
  const dispatch = useAppDispatch()

  const currentReview = useMemo(() => (
    relativeReviewList.find((el) => el.id === reviewIdFromNotification)
  ), [relativeReviewList, reviewIdFromNotification])

  useEffect(() => {
    if (hasReviewer) {
      reviewIdFromNotification && handleModal()
    }
  }, [reviewIdFromNotification, hasReviewer])

  const handleSend = () => {
    if (estimation) {
      dispatch(reviewThunks.setEstimationForReview({ reviewId: reviewIdFromNotification, estimation }))
    }
    handleApprovedModal()
    handleRatingModal()
  }

  const handleRatingModal = () => {
    handleModal()
    setEstimation(0)
    navigate(`/profile/${personId}`)
  }

  return (
    <DialogWrapper
      openModal={openModal}
      handleModal={handleRatingModal}
      width='532px'
      height={!isMobile ? '438px' : undefined}
      contentDividers
      buttons={
        <ModalButtons
          justifyContent={'right'}
          handleSend={handleSend}
          handleCancel={handleRatingModal}
          nameButtonSend={'Сохранить'}
        />
      }
    >
      <Stack gap={'24px'} sx={styleStack}>
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          Оцените владение навыком
        </Typography>
        <Typography variant='h4'>{currentReview?.skill.name}</Typography>
        <Typography variant='body2' color={(theme) => theme.palette.text.secondary}>
          У сотрудника
        </Typography>
        <Typography variant='h4' sx={styleTypography} alignItems={'center'} justifyContent={'center'}>
          {currentReview ? <UserAvatar userInfo={currentReview.owner} /> : ''}
          {`${currentReview?.owner.name} ${currentReview?.owner.surname}`}
        </Typography>
        <RatingCustom setEstimation={setEstimation} estimation={estimation} />
      </Stack>
    </DialogWrapper>
  )
}

export default RatingModal
