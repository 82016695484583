import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Navigation, Manipulation } from "swiper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//For library
import "swiper/css";
import "swiper/css/navigation";

import CreateStory from "./CreateStory/CreateStory";
import UserStoryGroup from "./UserStoryGroup/UserStoryGroup";
import CelebrationStoryGroup from "./CelebrationStoryGroup/CelebrationStoryGroup";
import { roles } from "../../../constants/role";
import { storyController } from "../../../services/story.controller";
import { StoryGroupView } from "../../../models/story.model";
import { dataInitial } from "../../../actions/data";
import ModalStories from "./ModalStories/ModalStories";
import { GroupStoriesType } from "../../../models/story.model";
import Snackbars from "../../common/Snackbars/Snackbars";

export type GroupInfo = {
  id: string;
  type: GroupStoriesType;
  pinned: boolean;
};

export const Stories: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("mobile"));
  const dispatch = useDispatch();

  const currentUserRole = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  ).role;

  const storyGroups: StoryGroupView[] = useAppSelector(
    (state) => state.dataReducer.storyGroups
  );

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  useEffect(() => {
    storyController.allStoryGroup(currentPage).then((res) => {
      dispatch(dataInitial.storyGroups(res.data.items));
      setCurrentPage((prevState) => prevState + 1);
      setTotalItems(res.data.totalItems);
    });
    /* eslint-disable */
  }, []);

  const storyId = window.location.search.slice(7);
  useEffect(() => {
    const storyGroup = storyGroups?.find(storyGroup => storyGroup.id === storyId);
    if (storyGroup) {
      handleClickStoryGroup(storyGroup);
      window.history.replaceState(null, "", "/feed");
    }
  }, [storyId, storyGroups]);

  /* Minimum number of stories we need in advance
  before we preload another slice of stories */
  const preloadThreshold = isMobile ? 6 : 8;
  
  const getNextStoryGroupRecords = (swiper: SwiperClass) => {
    if (
      swiper.slides.length - swiper.activeIndex < preloadThreshold
      && storyGroups.length < totalItems
    ) {
      storyController.allStoryGroup(currentPage).then((res) => {
        dispatch(dataInitial.storyGroups([...storyGroups, ...res.data.items]));
        setCurrentPage((prevState) => prevState + 1);
      });
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleModal = () => setOpenModal(!openModal);

  const [groupInfo, setGroupInfo] = useState<GroupInfo>();

  const handleClickStoryGroup = (item: StoryGroupView) => {
    storyController
      .storiesFromGroup(item.id)
      .then((res) => dispatch(dataInitial.groupStories(res.data.items)))
      .then(() =>
        setGroupInfo({ id: item.id, type: item.groupType, pinned: item.pinned })
      )
      .then(() => setOpenModal(true));
  };

  const GroupStory = ({ item }: { item: StoryGroupView }) => {
    if (item.groupType === "BIRTHDAY") {
      return (
        <CelebrationStoryGroup
          type="birthday"
          storyGroup={item}
          onClick={() => {
            handleClickStoryGroup(item);
          }}
        />
      );
    }
    if (item.groupType === "WELCOME") {
      return (
        <CelebrationStoryGroup
          type="welcome"
          storyGroup={item}
          onClick={() => handleClickStoryGroup(item)}
        />
      );
    }
    return (
      <UserStoryGroup
        title={item.firstStories[0]?.header}
        backgroundUrl={`/loyalty-portal/api/attachments/${
          encodeURIComponent(item.firstStories[0]?.attachments[0]?.name) +
          item.firstStories[0]?.attachments[0]?.extension
        }`}
        onClick={() => handleClickStoryGroup(item)}
      />
    );
  };

  const styleSwiper = {
    "& > .swiper": {
      height: "100%",
      marginLeft: `${isMobile ? "16px" : 0}`,
    },

    "& > .swiper > .swiper-wrapper > .swiper-slide": {
      width: `${isMobile ? 144 : 224}px !important`,
      height: "100%",
      borderRadius: "12px",
    },

    "& > .swiper > .swiper-button-prev, & > .swiper > .swiper-button-next": {
      color: "rgba(229, 235, 242, 0.65)",
      padding: "16px",
      borderRadius: "180px",
      backgroundColor: "rgba(203, 225, 255, 0.17)",
      width: "48px",
      height: "48px",
      backdropFilter: "blur(25px)",
    },

    "& > .swiper > .swiper-button-prev::after, & > .swiper > .swiper-button-next::after":
      {
        fontSize: "12px",
      },
    "& > .swiper > .swiper-button-prev": {
      left: 0,
    },
    "& > .swiper > .swiper-button-next": {
      right: 0,
    },
  };

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const handleError = () => setOpenSnackbar(true);

  const storyVisibility =
    storyGroups.length > 0 || currentUserRole === roles.admin;

  return (
    <>
      {storyVisibility && (
        <Box height={isMobile ? 204 : 344} sx={styleSwiper}>
          <Swiper
            spaceBetween={16}
            slidesPerView="auto"
            slidesPerGroup={isMobile ? 1 : 3}
            modules={[Navigation, Manipulation]}
            navigation={isMobile ? false : true}
            centeredSlidesBounds
            centerInsufficientSlides
            slidesOffsetAfter={0}
            normalizeSlideIndex={false}
            onActiveIndexChange={getNextStoryGroupRecords}
          >
            {currentUserRole !== roles.user && (
              <SwiperSlide>
                <CreateStory setOpenSnackbar={setOpenSnackbar} />
              </SwiperSlide>
            )}
            {storyGroups.map((item: StoryGroupView, index: number) => (
              <SwiperSlide key={index}>
                <GroupStory item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}
      <ModalStories
        openModal={openModal}
        handleModal={handleModal}
        groupInfo={groupInfo}
        setGroupInfo={setGroupInfo}
        handleError={handleError}
      />
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </>
  );
};

export default Stories;
