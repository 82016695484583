import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { FeedRecordView } from "../../../models/feed.model";
import { secondaryButton } from "../../../constants/buttonStyle";
import { determineDate } from "../../../operation/determineDate";
import UserAvatar from "../../common/User/UserAvatar/UserAvatar";
import PreviewFiles from "../../common/Attachments/PreviewFiles/PreviewFiles";
import { AttachmentView } from "../../../models/attachment.model";
import PreviewImages from "../../common/Attachments/PreviewImages/PreviewImages";
import Reactions from "../../common/Reactions/Reactions";
import { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import EditPopover from "../../common/Modals/EditPopover/EditPopover";
import EditCommentModal from "../../common/comments/Comment/EditCommentModal/EditCommentModal";
import { feedController } from "../../../services/feed.controller";
import { roles } from "../../../constants/role";

interface FeedMessageProp {
  messageData: FeedRecordView;
  withButton?: boolean;
  withEllipsis?: boolean;
  handleError: () => void;
  updateFeed?: () => void;
  updateFeedItem?: () => void;
}

export const FeedMessage: FC<FeedMessageProp> = ({
  messageData,
  withButton,
  withEllipsis,
  handleError,
  updateFeed,
  updateFeedItem,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const images =
    messageData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType === "IMAGE"
    ) ?? [];
  const files =
    messageData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType !== "IMAGE"
    ) ?? [];

  const cardActionsStyle = {
    padding: {
      mobile: "8px 16px 16px",
      laptop: "8px 24px 24px",
    },
    display: "flex",
    flexWrap: "wrap",
  };

  const ellipsisStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "175px",
  };

  const userNameStyle = {
    wordBreak: "break-all",
  };

  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const deleteFeed = () => {
    feedController
      .deleteFeedItem(messageData.id)
      .then(() => {
        updateFeed?.();
        updateFeedItem && navigate("..", { relative: "path" });
      })
      .catch(() => handleError());
  };

  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const editMessage = (
    newContent: string,
    closeModal: () => void,
    files?: any[]
  ) => {
    feedController
      .updateFeedItem(messageData.id, { content: newContent })
      .then(() => {
        const deleteAttach = () => {
          if (deleteFiles.length > 0) {
            return feedController.deleteAttachFeedItem(messageData.id, {
              attachmentsIds: deleteFiles,
            });
          }
        };
        const addAttach = () => {
          const newFiles = files?.filter(
            (item) => item.webkitRelativePath === "" //take only new files
          );
          if (newFiles && newFiles.length > 0) {
            const formData = new FormData();
            [...newFiles].forEach((file) => {
              formData.append("file", file);
            });
            return feedController.addAttachFeedItem(messageData.id, formData);
          }
        };
        Promise.all([deleteAttach(), addAttach()])
          .then(() => {
            closeModal();
            updateFeedItem?.();
            updateFeed?.();
          })
          .catch(() => handleError());
      })
      .catch(() => handleError());
  };

  const editButton: buttonInfo = {
    name: "Редактировать",
    action: () => handleModal(),
  };

  const deleteButton: buttonInfo = {
    name: "Удалить",
    action: () => deleteFeed(),
  };

  return (
    <>
      <Card id={messageData.id} sx={{ borderRadius: "12px", width: "100%" }}>
        <CardContent
          sx={{ padding: isMobile ? "16px 16px 8px" : "24px 24px 8px" }}
        >
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap="12px">
                <UserAvatar userInfo={messageData?.author} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle2"
                    sx={withEllipsis ? ellipsisStyle : userNameStyle}
                    >
                    {messageData?.author?.name +
                      " " +
                      messageData?.author?.surname}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={theme?.palette?.text?.secondary}
                  >
                    {determineDate(messageData.date)}
                  </Typography>
                </Box>
              </Box>
              {(currentUser.id === messageData?.author.id && (
                <EditPopover buttonsData={[editButton, deleteButton]} />
              )) || (currentUser.role === roles.admin && (
                <EditPopover buttonsData={[deleteButton]} />
              ))}
            </Box>
            <Typography variant="body2">{messageData?.content}</Typography>
            {images?.length > 0 && <PreviewImages images={images} />}
            {files?.length > 0 && (
              <PreviewFiles files={files} type="download" />
            )}
          </Stack>
        </CardContent>
        <CardActions sx={cardActionsStyle}>
          <Reactions
            reactionId={messageData.reaction.id}
            handleError={handleError}
          />
          {withButton && (
            <Button
              fullWidth
              sx={{
                borderRadius: "60px",
                padding: "12px 0",
                mt: "16px",
                ...secondaryButton,
              }}
              component={NavLink}
              to={`/feed/${messageData.id}`}
              onClick={() => localStorage.setItem("feedId", messageData.id)}
            >
              {messageData?.commentCount > 0
                ? `Комментарии (${messageData?.commentCount})`
                : "Оставить комментарий"}
            </Button>
          )}
        </CardActions>
      </Card>
      {openModal && (
        <EditCommentModal
          openModal={openModal}
          handleModal={handleModal}
          comment={messageData}
          handleSend={editMessage}
        />
      )}
    </>
  );
};
export default FeedMessage;
