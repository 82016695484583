import React, { useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';
import Slider from '@mui/material/Slider';
import ModalButtons from '../../../common/Buttons/ModalButtons/ModalButtons';

const boxStyle = {
  width: '300px',
  height: '300px',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const EditAvatarModal = ({ src, modalOpen, setModalOpen, setNewAvatar }: any) => {
  const [slideValue, setSlideValue] = useState<number>(10);
  const cropRef = useRef<AvatarEditor | null>(null);

  const handleClose = () => {
    setModalOpen((state: boolean) => !state);
  };

  //test
  const handleSave = async () => {
    if (cropRef.current) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      fetch(dataUrl)
        .then((response) => response.blob())
        .then((blob) => {
          setNewAvatar(blob);
          setModalOpen(false);
        });
    }
  };

  const handleSliderValue = (event: Event, newValue: number | number[]) => {
    setSlideValue(newValue as number);
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <AvatarEditor
          ref={cropRef}
          image={src}
          style={{ width: '100%', height: '100%' }}
          border={50}
          borderRadius={150}
          color={[0, 0, 0, 0.72]}
          scale={slideValue / 10}
          rotate={0}
        />
        <Slider
          min={10}
          max={50}
          sx={{
            margin: '0 auto',
            width: '80%',
          }}
          size='medium'
          defaultValue={slideValue}
          value={slideValue}
          onChange={handleSliderValue}
        />
        <Box
          sx={{
            display: 'flex',
            padding: '10px',
          }}
        >
          <ModalButtons handleSend={handleSave} handleCancel={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
};
