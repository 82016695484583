import React, { FC, Dispatch, SetStateAction } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { CommentView } from "../../../../models/comment.models";
import { determineDate } from "../../../../operation/determineDate";
import UserAvatar from "../../User/UserAvatar/UserAvatar";
import { AttachmentView } from "../../../../models/attachment.model";
import PreviewImages from "../../Attachments/PreviewImages/PreviewImages";
import PreviewFiles from "../../Attachments/PreviewFiles/PreviewFiles";
import Reactions from "../../Reactions/Reactions";
import { CommunityRecordView } from "../../../../models/community.models";
import EditPopover from "../../Modals/EditPopover/EditPopover";
import { buttonInfo } from "../../Modals/EditPopover/EditPopover";
import EditCommentModal from "./EditCommentModal/EditCommentModal";

export type CommentInfo = (CommentView | CommunityRecordView) & {
  isIdenticalUser?: boolean;
  lastIdenticalMessage?: boolean;
};

interface CommentProps {
  commentData: CommentInfo;
  anchorId?: string;
  handleError: () => void;
  editButtons?: buttonInfo[];
  idForEdit: string;
  setIdForEdit: Dispatch<SetStateAction<string>>;
  handleEdit: (
    newContent: string,
    closeModal: () => void,
    newFiles?: any[]
  ) => void;
}

export const Comment: FC<CommentProps> = ({
  commentData,
  anchorId,
  handleError,
  editButtons,
  idForEdit,
  setIdForEdit,
  handleEdit,
}) => {
  const isLoggedUser =
    commentData?.author?.id ===
    JSON.parse(localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}").id;

  const images =
    commentData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType === "IMAGE"
    ) ?? [];
  const files =
    commentData?.attachments?.filter(
      (item: AttachmentView) => item.attachmentType !== "IMAGE"
    ) ?? [];

  const styleBox = {
    display: "flex",
    gap: "8px",
    flexDirection: isLoggedUser ? "row-reverse" : "row",
    mt: "24px",
    "& > .MuiBox-root": {
      alignSelf: "flex-end",
    },
    mb: commentData.isIdenticalUser ? "-20px" : "none",
  };

  const styleCard = {
    borderRadius: "12px",
    width: "100%",
    backgroundColor: isLoggedUser ? "rgba(51, 146, 255, 0.25)" : "none",
  };

  const styleAvatar = {
    visibility: commentData.isIdenticalUser ? "hidden" : "none",
  };

  const commentBodyStyle = {
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  };

  const closeModal = () => {
    setIdForEdit?.("");
  };

  return (
    <>
      <Box sx={styleBox} id={anchorId}>
        <Box sx={styleAvatar}>
          <UserAvatar userInfo={commentData?.author} />
        </Box>
        <Card sx={styleCard}>
          <CardContent
            sx={{
              padding: { mobile: "16px 16px 8px", tablet: "24px 24px 8px" },
            }}
          >
            <Stack spacing={"4px"}>
              {commentData?.lastIdenticalMessage && (
                <Typography variant="subtitle2">
                  {commentData?.author?.name} {commentData?.author?.surname}
                </Typography>
              )}
              <Typography variant="body2" sx={commentBodyStyle}>{commentData?.content}</Typography>
              {images?.length > 0 && <PreviewImages images={images} />}
              {files?.length > 0 && (
                <PreviewFiles files={files} type="download" />
              )}
              <Typography variant="body1" color="secondary">
                {determineDate(commentData.date)}
              </Typography>
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              padding: { mobile: "8px 16px 16px", tablet: "8px 24px 24px" },
              display: "block",
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="end">
              <Reactions
                reactionId={commentData.reaction.id}
                handleError={handleError}
              />
              {editButtons && <EditPopover buttonsData={editButtons} />}
            </Box>
          </CardActions>
        </Card>
      </Box>
      {idForEdit === commentData.id && (
        <EditCommentModal
          openModal={!!idForEdit}
          handleModal={closeModal}
          comment={commentData}
          handleSend={handleEdit}
        />
      )}
    </>
  );
};

export default Comment;
