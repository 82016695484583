import { ReviewInfo } from '../review'

export const initialReview: ReviewInfo = {
  reviewList: [],
  reviewListSize: 0,
  reviewListPage: 0,
  relativeReviewList: [],
  isAddLoadList: false,
  skillList: [],
  estimations: {},
  isReviewInitialized: false,
  snackBar: {
    message: '',
    open: false,
    type: 'success',
  },
}
