import { initialReview } from './initialStates/initialReview'
import { reviewController } from '../services/review.controller'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAppAsyncThunk } from './utils/createAppAsyncThunk'
import { EditReviewParams, ReviewList, ReviewRating, Skill } from '../models/review.models'
import { skillController } from '../services/skill.controller'
import { AnyAction } from 'redux'
import { SnackBarType } from '../components/common/Snackbars/Snackbars'
import { textSnackbars } from '../constants/snackBarNotifications'
import { reviewListSelect } from './selectors/reviewSelectors'

export type ReviewInfo = {
  reviewList: ReviewList[]
  reviewListSize: number
  reviewListPage: number
  relativeReviewList: ReviewList[]
  isAddLoadList: boolean
  skillList: Skill[]
  estimations: {
    [key: string]: {
      items: ReviewRating[]
      totalItems: number
    }
  }
  isReviewInitialized: boolean
  snackBar: {
    message: string
    open: boolean
    type: SnackBarType
  }
}

export type ReviewListRequestType = {
  userId: string;
  page: number;
  size: number;
}

const initialState: ReviewInfo = initialReview

const getFetchedReviewList = createAppAsyncThunk<{ reviewList: ReviewList[], reviewListSize: number }, ReviewListRequestType>(
  'review/review_list',
  async (request: ReviewListRequestType, thunkAPI) => {
    const { dispatch, rejectWithValue, getState } = thunkAPI
    const reviewList = reviewListSelect(getState());

    if (reviewList.length === 0) {
      dispatch(reviewActions.setReviewInitialized({ isReviewInitialized: true }))
    } else {
      dispatch(reviewActions.setIsAddLoadList({ isAddLoadList: true }))
    }

    try {
      const res = await reviewController.getAllReview(request.userId, request.page, request.size)
      if (reviewList.length === 0) {
        dispatch(reviewActions.setReviewInitialized({ isReviewInitialized: false }))
      } else {
        dispatch(reviewActions.setIsAddLoadList({ isAddLoadList: false }))
      }
      return { reviewList: res.data.items, reviewListSize: res.data.totalItems }
    } catch (e) {
      return rejectWithValue(textSnackbars.default)
    }
  },
)

const getFetchedRelativeReviewList = createAppAsyncThunk<{ reviewList: ReviewList[] }, string>(
  'review/relative_review_list',
  async (userId: string, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await reviewController.getAllRelativeReview(userId)
      return { reviewList: res.data.items }
    } catch (e) {
      return rejectWithValue(textSnackbars.default)
    }
  },
)

const getFetchedSkills = createAppAsyncThunk<Skill[]>('review/review_skills', async (_, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI
  const res = await skillController.getSkills()
  try {
    dispatch(reviewActions.skillList(res.data.items))
  } catch (e) {
    return rejectWithValue(textSnackbars.default)
  }
  return res.data.items
})

const createReview = createAppAsyncThunk<
  { review: ReviewList },
  { ownerId: string; reviewersId: string[]; skillId: string }
>('review/create_review', async (arg, thunkAPI) => {
  const { rejectWithValue } = thunkAPI
  try {
    const res = await reviewController.createReview(arg)
    return { review: res.data }
  } catch (e) {
    return rejectWithValue(textSnackbars.default)
  }
})

const editReview = createAppAsyncThunk<void, EditReviewParams>('review/edit_review', async (arg, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI
  const res = await reviewController.editReview(arg.reviewId, arg.body)
  try {
    dispatch(reviewActions.updateReviewList(res.data))
  } catch (e) {
    return rejectWithValue(textSnackbars.default)
  }
})

const deleteReview = createAppAsyncThunk<{ reviewId: string }, { reviewId: string }>(
  'review/delete_review',
  async (arg, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const res = await reviewController.deleteReview(arg.reviewId)
      return { reviewId: arg.reviewId }
    } catch (e) {
      return rejectWithValue(textSnackbars.default)
    }
  },
)

const setEstimationForReview = createAppAsyncThunk<void, { reviewId: string; estimation: number }>(
  'review/set_estimation_for_review',
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const res = await reviewController.setEstimation(arg.reviewId, {
      rating: arg.estimation,
    })
    try {
      dispatch(reviewActions.updateReviewList(res.data.review))
    } catch (e) {
      return rejectWithValue(textSnackbars.default)
    }
  },
)

const getFetchedAllEstimations = createAppAsyncThunk<void, { reviewId: string }>(
  'review/estimations',
  async (arg, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const res = await reviewController.getAllEstimations(arg.reviewId)
    try {
      dispatch(reviewActions.setEstimations({ reviewId: arg.reviewId, items: res.data }))
    } catch (e) {
      return rejectWithValue(textSnackbars.default)
    }
  },
)

const slice = createSlice({
  name: 'review',
  initialState: initialState,

  reducers: {
    skillList: (state, action: PayloadAction<Skill[]>) => {
      state.skillList = action.payload
    },
    updateReviewList: (state, action: PayloadAction<ReviewList>) => {
      const index = state.reviewList.findIndex((el) => el.id === action.payload.id)
      if (index !== -1) {
        state.reviewList[index] = action.payload
      } else {
        state.reviewList.push(action.payload)
      }
    },
    setEstimations: (state, action: PayloadAction<{ reviewId: string; items: any }>) => {
      //TODO bug: сохраняются оценки в ассоциативном массиве при переходе на другого юзера
      const { reviewId, items } = action.payload
      if (!state.estimations[reviewId]) {
        state.estimations[reviewId] = { items: [], totalItems: 0 }
      }
      state.estimations[reviewId] = {
        ...state.estimations[reviewId],
        ...items,
      }
    },
    setReviewInitialized: (state, action: PayloadAction<any>) => {
      state.isReviewInitialized = action.payload.isReviewInitialized
    },
    setIsAddLoadList: (state, action: PayloadAction<any>) => {
      state.isAddLoadList = action.payload.isAddLoadList
    },
    changeOpenSnackBar: (state, action: PayloadAction<boolean>) => {
      state.snackBar.open = action.payload
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFetchedReviewList.rejected, (state, action) => {
        setSnackBar(state, action, 'error')
      })
      .addCase(getFetchedRelativeReviewList.rejected, (state, action) => {
        setSnackBar(state, action, 'error')
      })
      .addCase(createReview.rejected, (state, action) => {
        setSnackBar(state, action, 'error')
      })
      .addCase(editReview.rejected, (state, action) => {
        setSnackBar(state, action, 'error')
      })
      .addCase(deleteReview.rejected, (state, action) => {
        setSnackBar(state, action, 'error')
      })
      .addCase(getFetchedReviewList.fulfilled, (state, action) => {
        state.reviewList = action.payload.reviewList
        state.reviewListSize = action.payload.reviewListSize
      })
      .addCase(getFetchedRelativeReviewList.fulfilled, (state, action) => {
        state.relativeReviewList = action.payload.reviewList
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.reviewList.push(action.payload.review)
        setSnackBar(state, { payload: textSnackbars.review.create }, 'success')
      })
      .addCase(editReview.fulfilled, (state, _) => {
        setSnackBar(state, { payload: textSnackbars.review.edit }, 'success')
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.reviewList = state.reviewList.filter((review) => review.id !== action.payload.reviewId)
        setSnackBar(state, { payload: textSnackbars.review.delete }, 'success')
      })
      .addMatcher(isError, (state, action) => {
        setSnackBar(state, action, 'error')
      })
  },
})

const setSnackBar = (state: any, action: AnyAction | { payload: string }, type: SnackBarType) => {
  state.snackBar.message = action.payload
  state.snackBar.type = type
  state.snackBar.open = true
}

function isError(action: AnyAction) {
  return action.type.endsWith('rejected')
}

export const reviewReducer = slice.reducer
export const reviewActions = slice.actions
export const reviewThunks = {
  getFetchedReviewList,
  getFetchedRelativeReviewList,
  getFetchedAllEstimations,
  createReview,
  deleteReview,
  editReview,
  setEstimationForReview,
  getFetchedSkills,
}
