import React, {
  FC,
  SetStateAction,
  Dispatch,
  useState,
  useContext,
} from "react";

import Stack from "@mui/material/Stack";

import { UserInfo } from "../../../../types/UserInfo.types";
import CustomSelect from "../../../common/Inputs/CustomSelect/CustomSelect";
import { useJobTitiles } from "../../../../hooks/useJobTitiles";
import { useDepartments } from "../../../../hooks/useDepartments";
import { AppContext } from "../../../shared/AppContextProvider";

interface PersonalInfoProps {
  personData: UserInfo;
  setPersonData: Dispatch<SetStateAction<UserInfo>>;
  disabled: boolean;
}

export const Job: FC<PersonalInfoProps> = ({
  personData,
  setPersonData,
  disabled,
}) => {
  const { isMobile } = useContext(AppContext);

  const [pageTitle, setPageTitle] = useState<number>(0);
  const [pageDepartment, setPageDepartment] = useState<number>(0);

  const titles = useJobTitiles(pageTitle);
  const departments = useDepartments(pageDepartment);

  const handleChangeInputText =
    (prop: keyof UserInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPersonData({ ...personData, [prop]: event.target.value });
    };

  const handleScroll =
    (setPage: Dispatch<SetStateAction<number>>) => (event: any) => {
      const target = event.target;
      if (
        // +1 - it's test, for some users, scrolling does not work because 1px is lost
        target.scrollTop + target.clientHeight + 1 >= target.scrollHeight &&
        titles.titles.length < titles.size
      ) {
        setPage((prev) => prev + 1);
      }
    };

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      sx={{ width: "100%" }}
      gap={isMobile ? "32px" : "12px"}
    >
      <CustomSelect
        items={titles.titles}
        value={titles.titles ? personData?.jobTitle : ""}
        onChange={handleChangeInputText("jobTitle")}
        disabled={disabled}
        onScroll={handleScroll(setPageTitle)}
        placeholder="Выберите должность"
        height="400px"
      />
      <CustomSelect
        items={departments.departments}
        value={departments.departments ? personData?.department : ""}
        onChange={handleChangeInputText("department")}
        disabled={disabled}
        onScroll={handleScroll(setPageDepartment)}
        placeholder="Выберите департамент"
        height="400px"
      />
    </Stack>
  );
};

export default Job;
