import React, { FC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Link from "@mui/material/Link";

import UserAvatar from "../../../../common/User/UserAvatar/UserAvatar";
import { theme } from "../../../../../constants/theme";
import { PersonView } from "../../../../../models/profile.models";
import bg from "../../../../../assets/images/backgroudDefault.png";
import joyBg from "../../../../../assets/images/joyBackground.png";
import JoyHelpButton from "../../../../common/Buttons/JoyHelpButton/JoyHelpButton";

interface MemberPopoverCardProps {
  userInfo: PersonView;
  isJoy?: boolean;
}

export const MemberPopover: FC<MemberPopoverCardProps> = ({
  userInfo,
  isJoy,
}) => {
  const styleBoxCenter = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const styleCard = {
    minWidth: 280,
    maxWidth: 666,
    height: "auto",
    backgroundColor: "#212633",
  };

  const styleContent = {
    marginTop: "-35px",
    "&:last-child": {
      paddingBottom: "16px",
    },
  };

  const userNameStyle = {
    wordBreak: "break-all",
  };

  const telegramUsername = userInfo.contact.telegram?.substring(1);
  const personalInfo = [
    { title: "E-mail:", value: userInfo.contact.email },
    { title: "Тел.:", value: userInfo.contact.phone },
    { title: "WhatsApp:", value: userInfo.contact.phone },
    {
      title: "Telegram:",
      value: userInfo.contact.telegram ? (
        <Link
          href={`https://telegram.me/${telegramUsername}`}
          underline="hover"
          target="_blank"
        >
          {userInfo.contact.telegram}
        </Link>
      ) : (
        ""
      ),
    },
  ];

  const imgSrc = isJoy
    ? joyBg
    : userInfo.background
    ? `/loyalty-portal/api/attachments/${userInfo.background.name}${userInfo.background.extension}`
    : bg;
  const bgStyle = {
    height: 76,
    backgroundImage: `url(${imgSrc})`,
  };

  return (
    <Card sx={styleCard}>
      <CardMedia component="div" sx={bgStyle} />
      <CardContent sx={styleContent}>
        <Box sx={styleBoxCenter}>
          <UserAvatar withBorder userInfo={userInfo} />
          <Typography variant="subtitle2" mt="12px" sx={userNameStyle}>
            {userInfo.name + " " + userInfo.surname}
          </Typography>
          <Typography variant="body1" color={theme?.palette?.text?.secondary}>
            {userInfo?.job?.title.name}
          </Typography>
        </Box>
        <Box>
          {isJoy ? (
            <Box mt="12px">
              <JoyHelpButton color="secondary" fullWidth disabled />
            </Box>
          ) : (
            <List sx={{ padding: "12px 0 0 0" }}>
              {personalInfo.map(
                (item, index) =>
                  item.value && (
                    <ListItem key={index} sx={{ padding: 0, gap: 1 }}>
                      <ListItemText
                        primary={item.title}
                        sx={{ width: "77px", flex: "none" }}
                      />
                      <ListItemText primary={item.value} />
                    </ListItem>
                  )
              )}
            </List>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MemberPopover;
