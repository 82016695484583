import { AppState } from '../../store'

export const reviewListSelect = (state: AppState) => state.reviewReducer.reviewList

export const reviewListSizeSelect = (state: AppState) => state.reviewReducer.reviewListSize

export const reviewListPageSelect = (state: AppState) => state.reviewReducer.reviewListPage

export const reviewListLoadMoreSelect = (state: AppState) => state.reviewReducer.isAddLoadList

export const reviewEstimationsSelect = (state: AppState) => state.reviewReducer.estimations

export const relativeReviewListSelect = (state: AppState) => state.reviewReducer.relativeReviewList

export const isReviewInitializedSelect = (state: AppState) => state.reviewReducer.isReviewInitialized

export const skillListSelect = (state: AppState) => state.reviewReducer.skillList

export const reviewSnackBar = (state: AppState) => state.reviewReducer.snackBar
