import React, { FC } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import ShowMoreButton from "../../../KnowledgeBase/ShowMoreButton/ShowMoreButton";

interface CustomSelectProp {
  items: string[];
  onChange: (props?: any) => void;
  value: string;
  translator?: (props?: any) => string;
  disabled?: boolean;
  placeholder?: string;
  showMoreFunc?: () => void;
  onScroll?: (event: any) => void;
  height?: string;
}

export const CustomSelect: FC<CustomSelectProp> = ({
  items,
  onChange,
  value,
  translator,
  disabled,
  placeholder,
  showMoreFunc,
  onScroll,
  height,
}) => {
  const styleMenu = {
    "& .MuiMenu-paper": {
      backgroundColor: "#212633",
      height: height,
    },
  };

  const styleSelect = {
    "&:before": {
      borderBottom: "1px solid rgba(241, 247, 255, 0.12)",
    },
    "&:hover:not(.Mui-disabled):before, &:after": {
      borderBottom: "1px solid rgba(229, 235, 242, 0.65)",
    },
    "&:focus-within:before, &:after": {
      borderBottom: "1px solid rgba(24, 132, 255, 1)",
    },
    "&.Mui-error:after": {
      borderBottom: "1px solid rgba(224, 63, 72, 1)",
    },
    fontSize: "16px",
    "& > svg": { color: "rgba(229, 235, 242, 0.65)" },
    "& em": {
      color: "rgba(229, 235, 242, 0.65)",
      fontStyle: "normal",
    },
  };

  const showMoreStyle = {
    p: "16px",
  };

  const menuItemStyle = {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "666px",
  };

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          sx: styleMenu,
          PaperProps: {
            onScroll: onScroll,
          },
          MenuListProps: {
            sx: {
              display: "grid",
            },
          },
        }}
        sx={styleSelect}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {items.map((item: string, index: number) => (
          <MenuItem value={item} key={index} sx={menuItemStyle}>
            {translator ? translator(item) : item}
          </MenuItem>
        ))}
        {value !== "" && !placeholder && !items.includes(value) && (
          <MenuItem value={value} sx={menuItemStyle}>
            {translator ? translator(value) : value}
          </MenuItem>
        )}
        {showMoreFunc && (
          <ShowMoreButton style={showMoreStyle} onClick={showMoreFunc} />
        )}
      </Select>
    </FormControl>
  );
};
export default CustomSelect;
