import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { PersonView } from "../../../../models/profile.models";
import UserAvatar from "../UserAvatar/UserAvatar";
import { theme } from "../../../../constants/theme";
import LoggedPopover from "../../../layout/RightSidebar/LoggedUser/LoggedPopover/LoggedPopover";
import { PersonCommunityView } from "../../../../models/community.models";
import { communityRole } from "../../../../translators/communityRole.translate";

interface UserInfoProps {
  userInfo: PersonView | PersonCommunityView;
  withEllipsis: boolean;
  onlineStatus?: boolean; //For logged user in the right sidebar info
  isCurrentUser?: boolean;
  withNavigation?: boolean;
  onClick?: () => void;
}

export const UserInfo: FC<UserInfoProps> = ({
  userInfo,
  withEllipsis,
  onlineStatus,
  isCurrentUser,
  withNavigation,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (withNavigation) {
      localStorage.setItem("userId", userInfo.id);
      navigate(`/profile/${userInfo.id}`);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ellipsisStyle = {
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "175px",
  };

  const userNameStyle = {
    wordBreak: "break-all",
  };

  const addCommunityRole = (userInfo: PersonCommunityView) => {
    if (userInfo?.communityRole) {
      return `. ${communityRole(userInfo.communityRole)}`;
    }
    return;
  };

  return (
    <>
      <Box
        display="flex"
        gap="12px"
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        <UserAvatar userInfo={userInfo} />
        <Box display="grid" justifyContent="center">
          <Typography
            variant="subtitle2"
            sx={withEllipsis ? ellipsisStyle : userNameStyle}
          >
            {userInfo?.name + " " + userInfo?.surname}
          </Typography>
          <Typography variant="body1" color={theme?.palette?.text?.secondary}>
            {onlineStatus ? "В сети" : userInfo?.job?.title?.name}
            {addCommunityRole(userInfo as PersonCommunityView)}
          </Typography>
        </Box>
      </Box>
      {isCurrentUser && (
        <LoggedPopover
          userInfo={userInfo as PersonView}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default UserInfo;
