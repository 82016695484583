import React, { FC, useState, useEffect } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import FeedMessage from "../FeedMessage/FeedMessage";
import { feedController } from "../../../services/feed.controller";
import Comment, { CommentInfo } from "../../common/comments/Comment/Comment";
import { NavigateButton } from "../../common/comments/NavigateButton/NavigateButton";
import BottomActions from "../../common/BottomActions/BottomActions";
import TextMessageInput from "../../common/comments/TextMessageInput/TextMessageInput";
import { dataInitial } from "../../../actions/data";
import { FeedRecordView } from "../../../models/feed.model";
import { CommentView } from "../../../models/comment.models";
import { BackButton } from "../../common/Buttons/BackButton/BackButton";
import Snackbars from "../../common/Snackbars/Snackbars";
import { LoadProgress } from "../../common/LoadProgress/LoadProgress";
import { reactionController } from "../../../services/reaction.controller";
import { adaptComments } from "../../../operation/adaptComments";
import { buttonInfo } from "../../common/Modals/EditPopover/EditPopover";
import { roles } from "../../../constants/role";
import { feedInitialState } from "../Feed";
import { LoadOnScrollParams } from '../../../types/LoadOnScroll.types';

export const MessageComments: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("laptop"));

  const pathId = useLocation().pathname.split("/")[2];
  pathId && localStorage.setItem("feedId", pathId);
  const feedId = localStorage.getItem("feedId");
  const [newComment, setNewComment] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [newCommentLoader, setNewCommentLoader] = useState<boolean>(false);
  const [additionalLoader, setAdditionalLoader] = useState<boolean>(false);

  const feedItem: FeedRecordView = useAppSelector(
    (state) => state.dataReducer.feedItem
  );
  const feedComments: CommentView[] = useAppSelector(
    (state) => state.dataReducer.feedComments
  );

  const updateFeedRecords = () => {
    feedController
      .feed(0, feedInitialState.tabIndex === 0, feedInitialState.currentPage * 10)
      .then((res) => {
        dispatch(dataInitial.feed(res.data.items));
        feedInitialState.totalRecords = res.data.totalItems;
      });
  };

  const getFeedItem = () => {
    feedController
      .feedItem(feedId)
      .then((res) => dispatch(dataInitial.feedItem(res.data)));
  };
  useEffect(() => {
    getFeedItem();

    return function () {
      setCurrentPage(0);
      setIsFetching(true);
      setTotalComments(0);
      setLoader(true);
      dispatch(dataInitial.feedComments([]));
    };
    /* eslint-disable */
  }, [feedId]);

  useEffect(() => {
    if (isFetching) {
      feedController
        .feedItemComments(feedId, currentPage)
        .then((result) => {
          dispatch(
            dataInitial.feedComments([...feedComments, ...result.data.items])
          );
          setCurrentPage((prevState) => prevState + 1);
          setTotalComments(result.data.totalItems);
          setLoader(false);
        })
        .finally(() => setIsFetching(false));
    }
    /* eslint-disable */
  }, [isFetching, feedId]);

  const handleLoadOnScroll = (params: LoadOnScrollParams) => {
    const { scrollHeight, scrollTop, viewportHeight } = params;
    if (
      (scrollHeight - (scrollTop + viewportHeight) < 100)
      && (feedComments.length < totalComments)
    ) {
      setIsFetching(true);
      setAdditionalLoader(true);
    }
  };

  const scrollHandler = (e: any) => {
    handleLoadOnScroll({
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      viewportHeight: window.innerHeight,
    });
  };

  const windowScrollHandler = () => {
    handleLoadOnScroll({
      scrollHeight: document.documentElement.scrollHeight,
      scrollTop: window.scrollY,
      viewportHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    document.getElementById("main-scroll")?.addEventListener("scroll", scrollHandler);
    window.addEventListener("scroll", windowScrollHandler);

    return function () {
      document.getElementById("main-scroll")?.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("scroll", windowScrollHandler);
      setAdditionalLoader(false);
    };
  }, [feedComments.length, totalComments, feedId]);

  useEffect(() => {
    reactionController
      .smiles()
      .then((res) => dispatch(dataInitial.smiles(res.data.items)))
      .catch(() => {
        setOpenSnackbar(true);
      });
  }, []);

  const comments = adaptComments(feedComments);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const handleError = () => setOpenSnackbar(true);

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const handleAttachmentButton = async (event: any) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
    event.target.value = "";
  };

  const handleCancel = () => {
    setNewComment("");
    setSelectedFiles([]);
  };

  const postComment = (func?: () => void) => {
    setNewCommentLoader(true);
    const formData = new FormData();
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            content: newComment,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    [...selectedFiles].forEach((image) => {
      formData.append("files", image);
    });
    feedController
      .newFeedItemComment(localStorage.getItem("feedId"), formData)
      .then(() => {
        feedController
          .feedItemComments(localStorage.getItem("feedId"), 0)
          .then((result) => {
            dispatch(dataInitial.feedComments(result.data.items));
            updateFeedRecords();
          }),
          handleCancel(),
          func?.();
      })
      .catch(() => setOpenSnackbar(true))
      .finally(() => setNewCommentLoader(false));
  };

  const updateComments = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        feedController
          .feedItemComments(feedId, 0, currentPage * 10)
          .then((result) => {
            dispatch(dataInitial.feedComments(result.data.items));
            setTotalComments(result.data.totalItems);
          });
        resolve();
      }, 500);
    });
  };

  const deleteMessage = (id: string) => {
    feedController
      .deleteFeedItemComments(feedId, id)
      .then(() => {
        updateComments();
        updateFeedRecords();
      })
      .catch(() => setOpenSnackbar(true));
  };
  const deleteFiles = useAppSelector(
    (state) => state.manageDataReducer.deleteFiles
  );

  const editMessage =
    (comment: CommentInfo) =>
    (newContent: string, closeModal: () => void, files?: any[]) => {
      feedController
        .editFeedItemComments(feedId, comment.id, { content: newContent })
        .then(() => {
          const deleteAttach = () => {
            if (deleteFiles.length > 0) {
              return feedController.deleteAttachmentsFeedComments(
                feedId,
                comment.id,
                {
                  attachmentsIds: deleteFiles,
                }
              );
            }
          };
          const addAttach = () => {
            const newFiles = files?.filter(
              (item) => item.webkitRelativePath === "" //take only new files
            );
            if (newFiles && newFiles.length > 0) {
              const formData = new FormData();
              [...newFiles].forEach((file) => {
                formData.append("files", file);
              });
              return feedController.addAttachmentsFeedComments(
                feedId,
                comment.id,
                formData
              );
            }
          };
          Promise.all([deleteAttach(), addAttach()])
            .then(() => {
              closeModal();
              updateComments();
            })
            .catch(() => setOpenSnackbar(true));
        })
        .catch(() => setOpenSnackbar(true));
    };

  const [editCommentId, setEditCommentId] = useState<string>("");
  const currentUser = JSON.parse(
    localStorage.getItem("REACT_TOKEN_AUTH_KEY") || "{}"
  );
  const commentButtons = (
    id: string,
    authorId: string
  ): buttonInfo[] | undefined => {
    if (currentUser.id === authorId) {
      return [
        {
          name: "Редактировать",
          action: () => setEditCommentId(id),
        },
        {
          name: "Удалить",
          action: () => deleteMessage(id),
        },
      ];
    }
    if (currentUser.role === roles.admin) {
      return [{
        name: "Удалить",
        action: () => deleteMessage(id),
      }];
    }
  };

  return (
    <Box>
      <BackButton
        authorName={feedItem?.author?.name + " " + feedItem?.author?.surname}
        commentCount={feedItem.commentCount}
        handleNavigateBack={() => {
          if (feedInitialState.totalRecords) {
            feedInitialState.isPreserved = true;
            feedInitialState.shouldGetData = false;
            feedInitialState.idToScrollTo = feedItem.id;
          }
        }}
      />
      {loader && <LoadProgress />}
      {!loader && (
        <>
          <FeedMessage
            messageData={feedItem}
            handleError={handleError}
            updateFeed={updateFeedRecords}
            updateFeedItem={getFeedItem}
          />
          {newCommentLoader && <LoadProgress />}
          <Box sx={{ px: isMobile ? 2 : 0 }}>
            {comments.map((item, index, array) => (
              <Comment
                key={index}
                commentData={item}
                anchorId={
                  index === array.length - 1
                    ? "back-to-bottom-anchor"
                    : undefined
                }
                handleError={handleError}
                editButtons={commentButtons(item.id, item.author.id)}
                idForEdit={editCommentId}
                setIdForEdit={setEditCommentId}
                handleEdit={editMessage(item)}
              />
            ))}
          </Box>
          {additionalLoader && <LoadProgress />}
        </>
      )}
      {!isMobile && <NavigateButton />}
      <BottomActions>
        <TextMessageInput
          textMessage={newComment}
          setTextMessage={setNewComment}
          handleSent={postComment}
          handleAttach={(e) => handleAttachmentButton(e)}
          files={selectedFiles}
          setFiles={setSelectedFiles}
        />
      </BottomActions>
      <Snackbars
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        type="error"
        position="center"
      />
    </Box>
  );
};

export default MessageComments;
